import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import HeadingTextTwoColumns from "../components/HeadingTextTwoColumns/headingTextTwoColumns"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"
import { H1 } from "../styles/typography"
import { main } from "../styles/theme"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
  }
`

const Historia = () => (
  <Layout>
    <Seo title="Historia" />
    <HeadingWrapper>
      <H1 size="large">Historia</H1>
    </HeadingWrapper>
    <StaticImage
      src="../images/historia.jpg"
      quality={95}
      layout="fullWidth"
      formats={["AUTO", "WEBP"]}
      alt="Smedjans Historia"
      style={{ marginTop: `2.45rem` }}
    />
    <HeadingTextTwoColumns
      heading="Ett nytt kapitel i historien om O.A. Möllers smidesverkstad"
      text={[
        "När sjöfarten utvecklas under andra halvan av 1700-talet börjar folk som jobbar på sjön och med anknytning till hamnen att bygga bo i området kring Masthugget och mot Järntorget. Här finns två infartsvägar till Göteborg, Breda vägen och Smala vägen. Och här växer det fram en förstadsbebyggelse av trähus.",
        "På 1820-talet upprättas en plan för Masthugget. Den glesa bebyggelsen ska ersättas med nya raka gator, det som ska bli långgatorna. Och 1866 fastställs planerna med ett rutnätssystem. Stadsdelen byggs under en relativt kort period med stor variation av arkitekturstilar, framförallt i sten.",
        "Tredje Långgatan präglas av dess verkstäder, till skillnad från Andra Långgatan som har många butiker. Det första huset i kvarteret är ett skolhus som byggs 1877. Lite senare tillkommer det också verkstadsbyggnader och en polisstation som idag är en del av kvarteret Barken.",
      ]}
    />
    <ImageTextBlock
      text={[
        `År 1897 övertar Olof Anton Möller en verkstad på Breda vägen, nuvarande Fjärde Långgatan. Efter några år flyttar han in i nya byggnader på Tredje Långgatan 26. Med sitt korta telefonnummer 2774 är det enkelt att slå en signal om man vill ha hjälp med järnkonstruktioner, plåtslageriarbeten eller mekaniska reparationer. Vid den här tiden är O.A. Möller Smidesverkstad särskilt duktiga på ornament och byggnadssmiden. ”Allt utföres med tillhjälp af modernaste maskiner, hvarför moderata priser kan utlovas”.`,
      ]}
      imagePosition="right"
      offset={2}
      columns={4}
      image={
        <StaticImage
          src="../images/annons-OA-Moller-1915.png"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Annons ur Göteborgs adresskalender 1915"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      text={[
        `Fastigheten uppförs ursprungligen 1902 som ett gatuhus med två våningar, en inredd vindsvåning och ett gårdshus som blir smidesverkstaden. Innan dess ligger här ett stort trähus. Men av någon okänd anledning dyker fastigheten inte upp  i Göteborgs Adresskalender  förrän 1908 då taxerad till 35 000 kronor. Möller bor själv alldeles i närheten på Tredje Långgatan 17.`,
        `På den första kända ritningen från kvarteret år 1875 beskrivs en tillbyggnad av ett trapphus. År 1904 planerar man att bygga ett fyra våningars hus ut mot gatan, men planerna går i stöpet och det blir istället ett provisoriskt enplanshus med plats för kontor och förvaring.`,
      ]}
      imagePosition="left"
      offset={2}
      image={
        <StaticImage
          src="../images/originalritning-gard-cropped.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Originalritning gård"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      text={[
        `När Möller avlider 1921 tar hans bokhållare Edvin Lönn och verkmästare Fritiof E. Olsson över verksamheten som nu även omfattar Tredje Långgatan 28. Olsson drar sig snart ur firman medan Lönn driver den till sin död 1934. Familjen fortsätter att utveckla verksamheten och specialiserar sig på byggnads- och fartygssmide. Under trettiotalet har företaget ett trettiotal anställda och är Göteborg stads största leverantör inom branschen. Efter att företaget avvecklas är fastighetens historia dolt i dunkel, men fastigheten har fungerat både som boende och obskyra svartklubbar, konstnärskollektiv och andra verksamheter.`,
      ]}
      imagePosition="right"
      offset={1}
      columns={6}
      image={
        <StaticImage
          src="../images/originalritning-gata-cropped.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Originalritning gata"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      text={[
        `Idag är kvarteret och långgatorna med sina bevarade byggnader ett tidsdokument över en levande stadsdel. Genom att bevara och renovera den gamla fastigheten och bygga nytt lever gatans historia kvar samtidigt som vi skriver nästa kapitel.`,
      ]}
      imagePosition="left"
      offset={2}
      columns={4}
      image={
        <StaticImage
          src="../images/historia-rost-2.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Rostig balk"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
  </Layout>
)

export default Historia
